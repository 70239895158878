window.$ = require('jquery');
window.jQuery = window.$;

$( document ).ready(function() {
    
    $('.wdi_load_more_text').text('Visa fler');

	if ( $('body').hasClass('home') ) {

		$('.mobile-menu').addClass('open');

	} else {

	    $('.menu-icon').on('click', function(){
	    	$('.mobile-menu').toggleClass('open');
	    });

	    $('.current_page_item').on('click', function(){
	    	$('.mobile-menu').removeClass('open');
	    });
		
	}

});